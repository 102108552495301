import React from 'react'

const HeaderGeneric = props => (
  <header id="header">
    <h1>Generic</h1>
    <p>Ipsum dolor sit amet nullam</p>
  </header>
)

export default HeaderGeneric
